var windowWidth, windowHeight, documentWidth, documentHeight, topScrollPosition;
var siteRoot = window.location.protocol + "//" + window.location.host;

jQuery(document).ready(function($){

    console.log('custom script loaded');

    function recalculateSizes() {
        windowWidth = $(window).width(); //retrieve current window width
        windowHeight = $(window).height(); //retrieve current window height
        documentWidth = $(document).width(); //retrieve current document width
        documentHeight = $(document).height(); //retrieve current document height
        topScrollPosition = $(window).scrollTop(); //retrieve the document scroll ToP position
    }
    recalculateSizes();

    function getLensesStrength() {
        const lensesOptions = $('.lenses-selection option');
        console.log(lensesOptions);
        // lensesOptions.prop('disabled', true);
        lensesOptions.each(function() {
            let lenseVal = $(this).val();
            let lenseStrength = lenseVal.substr(0,lenseVal.indexOf(' '));
            $(this).attr('attr-strength', lenseStrength);
            console.log($(this));
        })
    }
    getLensesStrength();

    // execute on resize but only once
    var doResize;
    $(window).on('resize', function() {

        clearTimeout(doResize);
        doResize = setTimeout(function() {

        }, 300);
    });

    var doScroll;
    $(window).on('scroll', function() {

        clearTimeout(doScroll);
        doScroll = setTimeout(function() {

        }, 300);
    });

    $( window ).on( "orientationchange", function( event ) {

    });

});


jQuery(window).on('load', function() {
    // deferImages();
});